<template>
  <div>
    <b-container class="">
        <b-row class="mt-3 mx-3">
            <b-col 
                cols="12" md="4" v-for="igPost in igPosts" 
                :key="igPost.caption" 
                v-html="igPost.embed_code">
            </b-col>
        </b-row>
    </b-container>

    <b-container class="">
      <div class="alp-home-team-title mt-5" id="location-our-team">
        <!--<p class="alp-bodoni font-weight-bold text-left text-md-center pl-4 mb-0 text-danger">
                <span class="d-md-none" style="font-size: 0.875rem">OUR TEAM</span>
                <span class="d-none d-md-block" style="font-size: 1rem">OUR TEAM</span>
            </p> -->
        <p class="alp-bodoni font-weight-bold text-left mb-4">
          <span class="d-md-none alp-bodoni" style="font-size: 2rem">{{
            this.$t("Meet our Team")
          }}</span>
          <span class="d-none d-md-block alp-bodoni" style="font-size: 2rem">{{
            this.$t("Meet our Team")
          }}</span>
        </p>
      </div>
    </b-container>

    <!-- mobile heads -->
    <splide class="splide-danger alp-heads d-md-none my-4" :options="options">
      <splide-slide v-for="alpHead in alpHeads" :key="'1' + alpHead.name" class="pt-2">
        <div class="mx-2 alp-head shadow-alp rounded-alp">
          <img :src="'/img/' + alpHead.url" />
          <div class="alp-head-content">
            <p class="alp-bodoni font-weight-bold mb-1" style="font-size: 1.5rem">
              {{ alpHead.name }}
            </p>
            <p class="text-gold">{{ alpHead.title }}</p>
            <div v-if="alpHead.desc.length > 500">
              <span style="line-height: 1.625">{{ alpHead.desc.substring(0, 500) }}</span>
              <span style="line-height: 1.625" v-if="readMore[alpHead.name]">{{
                alpHead.desc.substring(500)
              }}</span>
              <span
                style="color: black; cursor: pointer; line-height: 1.625"
                v-if="!readMore[alpHead.name]"
                @click="setReadMore(alpHead.name)"
                >... {{ $t("Read more") }}</span
              >
            </div>
            <div v-else>
              <p style="line-height: 1.625" v-html="alpHead.desc"></p>
            </div>
          </div>
        </div>
      </splide-slide>
    </splide>

    <!-- desktop heads -->
    <div class="d-none d-md-block container alp-heads mb-5" style="padding-left: 6rem; padding-right: 6rem;">
      <b-row class="alp-heads d-flex justify-content-center">
        <b-col md="6" lg="6" xl="6" v-for="alpHead in alpHeads" :key="'0' + alpHead.name">
          <div class="alp-head shadow-alp rounded-alp">
            <img :src="'/img/' + alpHead.url" />
            <div class="alp-head-content">
              <p class="alp-bodoni font-weight-bold mb-1" style="font-size: 1.5rem">
                {{ alpHead.name }}
              </p>
              <p class="text-gold">{{ alpHead.title }}</p>
              <div v-if="alpHead.desc.length > 500">
                <span style="line-height: 1.625">{{
                  alpHead.desc.substring(0, 500)
                }}</span>
                <span style="line-height: 1.625" v-if="readMore[alpHead.name]">{{
                  alpHead.desc.substring(500)
                }}</span>
                <span
                  style="color: black; cursor: pointer; line-height: 1.625"
                  v-if="!readMore[alpHead.name]"
                  @click="setReadMore(alpHead.name)"
                  >... {{ $t("Read more") }}</span
                >
              </div>
              <div v-else>
                <p style="line-height: 1.625" v-html="alpHead.desc"></p>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>

    <b-container class="d-md-block">
      <b-row align-v="center">
        <b-col class="pr-md-5">
          <div id="fooddrink" style="margin-top: -100px"></div>
          <div style="margin-top: 100px"></div>
          <p style="font-size: 2rem" class="mb-3 d-md-block alp-bodoni">
            {{ this.$t("Food/Drink") }}
          </p>
          <p style="font-size: 1.2rem" class="mb-3 d-md-block alp-bodoni">
            {{ this.$t("philosophy") }}
          </p>
          <p class="text-alp-muted" v-html="this.$t('philosophyText')"></p>
          <!---
          <div v-if="this.$t('lang') == 'DE'">
          <a target="_blank" href="/pdf/menu_weinkarte.pdf" class="alp-bodoni" style="text-decoration: underline; font-size: 1.4rem; line-height: 2">Herunterladen Menükarte</a>
          </div>
          
          <div v-if="this.$t('lang') == 'EN'">
          <a target="_blank" href="/pdf/menu_weinkarte_eng.pdf" class="alp-bodoni" style="text-decoration: underline; font-size: 1.4rem">Download Menu</a>
          </div> -->
        </b-col>
      </b-row>
    </b-container>

    <b-container class="mb-4">
      <b-row>
        <b-col>
          <div id="sections" style="margin-top: -150px"></div>
          <div style="margin-top: 150px"></div>
          <div class="d-flex align-items-center mt-5 mb-2">
            <b-button
              class="mr-4"
              size="lg"
              v-if="sectionPreview"
              @click="goBackSection()"
              ><i class="fas fa-arrow-left fa-fw"></i> Back</b-button
            >
            <p style="font-size: 2rem" class="mb-0 d-none d-md-block alp-bodoni">
              <span v-if="!section && !sectionPreview">{{ this.$t("Products") }}</span>
              <span v-if="section && !sectionPreview">{{ section.title }}</span>
              <span v-if="section && sectionPreview">{{ sectionPreview.title }}</span>
            </p>
            <p style="font-size: 2rem" class="mb-0 d-md-none alp-bodoni">
              <span v-if="!section && !sectionPreview">{{ this.$t("Products") }}</span>
              <span v-if="section && !sectionPreview">{{ section.title }}</span>
              <span v-if="section && sectionPreview">{{ sectionPreview.title }}</span>
            </p>
          </div>

          <b-row v-if="!section">
            <b-col
              style="cursor: pointer"
              v-b-modal.modal-section
              @click="setSection(act)"
              class="mt-3"
              v-for="act in activities"
              :key="'section-' + act.title"
              cols="6"
              md="3"
            >
              <img class="rounded-alp d-block w-100" :src="'/img/sections/' + act.img" />
              <span class="d-block my-3" style="font-size: 1.25rem">{{ act.title }}</span>
            </b-col>
          </b-row>

          <b-row v-if="section && !sectionPreview">
            <b-col
              style="cursor: pointer"
              v-b-modal.modal-section
              @click="setSectionPreview(cont)"
              class="mt-3"
              v-for="cont in section.contents"
              :key="'section-cont-' + cont.title"
              cols="12"
              md="3"
            >
              <img
                class="rounded-alp d-block w-100 img-border"
                style="
                  max-width: 500px;
                  max-height: 300px;
                  min-height: 200px;
                  height: 300px;
                  object-fit: cover;
                "
                :src="'/img/sections/' + cont.img"
              />
              <span
                class="d-block my-3 alp-bodoni"
                style="font-size: 1.25rem; text-transform: uppercase"
                >{{ cont.title }}</span
              >
            </b-col>
          </b-row>

          <b-row v-if="section && sectionPreview">
            <b-col sm="12" md="auto">
              <img
                style="
                  max-width: 500px;
                  max-height: 500px;
                  height: 300px;
                  width: 300px;
                  object-fit: cover;
                "
                class="my-3 rounded-alp d-none d-md-block"
                :src="'/img/sections/' + sectionPreview.photo"
              />
            </b-col>
            <b-col sm="12" md="8">
              <p
                class="mt-3"
                v-html="sectionPreview.content"
                style="min-height: 300px"
              ></p>
              <p class="mt-3" v-html="sectionPreview.address"></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <!-- <b-container>
      <b-row>
        <b-col>
          <div id="products" style="margin-top: -150px"></div>
          <div style="margin-top: 150px"></div>
          <div class="d-flex align-items-center mt-5 mb-2">
            <b-button
              class="mr-4"
              size="lg"
              v-if="activityPreview"
              @click="goBackActivity()"
              ><i class="fas fa-arrow-left fa-fw"></i> Back</b-button
            >
            <p style="font-size: 2rem" class="mb-0 d-none d-md-block alp-bodoni">
              <span v-if="!activity && !activityPreview">{{ this.$t("Products") }}</span>
              <span v-if="activity && !activityPreview">{{ activity.title }}</span>
              <span v-if="activity && activityPreview">{{ activityPreview.title }}</span>
            </p>
            <p style="font-size: 2rem" class="mb-0 d-md-none alp-bodoni">
              <span v-if="!activity && !activityPreview">{{ this.$t("Products") }}</span>
              <span v-if="activity && !activityPreview">{{ activity.title }}</span>
              <span v-if="activity && activityPreview">{{ activityPreview.title }}</span>
            </p>
          </div>

          <b-row v-if="!activity">
            <b-col
              style="cursor: pointer"
              v-b-modal.modal-activity
              @click="setActivity(act)"
              class="mt-3"
              v-for="act in activities"
              :key="'activity-' + act.title"
              cols="6"
              md="3"
            >
              <img
                class="rounded-alp d-block w-100"
                :src="'/img/activities/' + act.img"
              />
              <span class="d-block my-3" style="font-size: 1.25rem">{{ act.title }}</span>
            </b-col>
          </b-row>

          <b-row v-if="activity && !activityPreview">
            <b-col
              style="cursor: pointer"
              v-b-modal.modal-activity
              @click="setActivityPreview(cont)"
              class="mt-3"
              v-for="cont in activity.contents"
              :key="'activity-cont-' + cont.title"
              cols="12"
              md="3"
            >
              <img
                class="rounded-alp d-block w-100 img-border"
                style="
                  max-width: 500px;
                  max-height: 300px;
                  min-height: 200px;
                  height: 300px;
                  object-fit: cover;
                "
                :src="'/img/activities/' + cont.img"
              />
              <span
                class="d-block my-3 alp-bodoni"
                style="font-size: 1.25rem; text-transform: uppercase"
                >{{ cont.title }}</span
              >
            </b-col>
          </b-row>

          <b-row v-if="activity && activityPreview" style="min-height: 700px">
            <b-col sm="12" md="auto">
              <img
                style="
                  max-width: 500px;
                  max-height: 500px;
                  height: 300px;
                  width: 100%;
                  object-fit: contain;
                "
                class="my-3 rounded-alp"
                :src="'/img/activities/' + activityPreview.photo"
              />
            </b-col>
            <b-col sm="12" md="8">
              <p class="mt-3" v-html="activityPreview.content"></p>
              <p class="mt-3" v-html="activityPreview.address"></p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container> -->
  </div>
</template>

<script>
export default {
  computed: {
    videoName() {
      if (this.$root.season == "winter") {
        return "/videos/winter.mp4";
      }

      return "/videos/summer.mp4";
    },
  },
  watch: {
    "$root.season": function (nv) {
      if (nv == "winter") {
        this.videos.splice(0, 1);
        this.videos.push("/videos/winter.mp4");
      } else {
        this.videos.splice(0, 1);
        // this.videos.push("/videos/summer.mp4");
      }
    },
  },
  methods: {
    setActivity(activity) {
      this.activity = activity;
    },
    setActivityPreview(content) {
      this.activityPreview = content;
      window.$("#products")[0].scrollIntoView();
    },
    goBackActivity() {
      if (this.activityPreview) {
        this.activityPreview = null;
        return;
      }

      if (this.activity) {
        this.activity = null;
        this.activityPreview = null;
        return;
      }
    },
    setSection(section) {
      this.section = section;
    },
    setSectionPreview(content) {
      this.sectionPreview = content;
      window.$("#sections")[0].scrollIntoView();
    },
    goBackSection() {
      if (this.sectionPreview) {
        this.sectionPreview = null;
        return;
      }

      if (this.section) {
        this.section = null;
        this.sectionPreview = null;
        return;
      }
    },
    goToDefaultSpannortApartment() {
      this.$router.push("/booking/spannort-gasthaus-and-restaurant/adventurer-small");
    },
    setReadMore(name) {
      console.log(this.readMore);
      let array = {};
      array[name] = true;
      this.readMore = { ...this.readMore, ...array };
      console.log(this.readMore);
    },
    loadIgPosts() {
        const url = '/latest_ig_posts.json';
        // disable caching
        // console.log("loading...")
        fetch(url)
            .then(response => {
                // Check if the response is ok (status in the range 200-299)
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();  // Parse JSON from the response
            })
            .then(data => {
                // Use the JSON data here
                // console.log(data);  // Log the data to the console
                this.igPosts = data.map(igPost => ({
                    caption: igPost.caption,
                    embed_code: igPost.embed_code.replace(`width="400" height="480"`, `width="100%" height="700"`)
                }))
                // You can store it in a variable here if needed for further use
            })
            .catch(error => {
                // Handle any errors
                console.error('There was a problem fetching the data:', error);
            });
    }
  },
  mounted() {
    window.runCommon();
    this.setActivity(this.activities[0]);
    this.setSection(this.sections[0]);
    this.loadIgPosts();
  },
  data() {
    return {
      readMore: {},
      igPosts: [],
      sections: [
        {
          title: "",
          img: "menu.jpg",
          contents: [
            {
              title: this.$t("Reservations"),
              img: "reservations.jpg",
              content: `<a href="tel:+41(0)41 500 11 33" class="alp-bodoni link" style="text-decoration: underline; font-size: 1.4rem">Tel: +41(0)41 500 11 33</a><br>
                      <a class="alp-bodoni link" style="text-decoration: underline; font-size: 1.4rem" href="https://mytools.aleno.me/reservations/v2.0/reservations.html?k=eyJrIjoid2l2dTVrM2lsNm15cnBiOWlwdzZ4bmViajhycnVkaWRpZ280bGZwODBsbzlhNGlweTEiLCJyIjoiaGZkYkwzNHpwZ2pHUmk4REIiLCJzIjoiaHR0cHM6Ly9teXRvb2xzLmFsZW5vLm1lLyJ9">Online reservation</a>`,
              photo: "reservations.jpg",
              address: "",
            },
            {
              title: this.$t("Menu"),
              img: "menu.jpg",
              content:
                this.$t("lang") == "EN"
                  ? `<a target="_blank" href="/pdf/20240909_MENÜ Herbst EN.pdf" class="link alp-bodoni" style="text-decoration: underline; font-size: 1.4rem">Download Menu</a>`
                  : `<a target="_blank" href="/pdf/20240909_MENÜ Herbst DE.pdf" class="alp-bodoni link" style="text-decoration: underline; font-size: 1.4rem; line-height: 2">Herunterladen Menükarte</a>`,
              photo: "menu.jpg",
              address: "",
            },
            {
              title: this.$t("Wine list"),
              img: "weinkarte2.jpg",
              content:
                this.$t("lang") == "EN"
                  ? `<a target="_blank" href="/pdf/20240709 Weinkarte Spannort.pdf" class="link alp-bodoni" style="text-decoration: underline; font-size: 1.4rem">Download Wine list</a>`
                  : `<a target="_blank" href="/pdf/20240709 Weinkarte Spannort.pdf" class="alp-bodoni link" style="text-decoration: underline; font-size: 1.4rem; line-height: 2">Herunterladen Weinkarte</a>`,
              photo: "weinkarte2.jpg",
              address: "",
            },
          ],
        },
      ],
      section: null,
      sectionPreview: null,
      activities: [
        {
          title: this.$t("Products"),
          img: "guided.jpg",
          contents: [
            {
              title: "Metzger",
              img: "gabriel.jpg",
              content:
                "Die Metzgerei Gabriel AG ist ein Familienbetrieb in der vierten Generation – klein genug, um als regionaler Betrieb zu gelten, aber gross genug, um die Gastronomie flexibel, zuverlässig und kompetent zu beliefern.",
              photo: "metzger-1-min.jpg",
              address: `<p>Metzger Gabriel AG<br>
                    Hauptstrasse 5<br>
                    6386 Wolfenschiessen<br>
                    Telefon: 041 629 70 30<br>
                    Telefax: 041 629 70 31<br>
                    <a href="http://www.metzgergabriel.ch">www.metzgergabriel.ch</a></p>`,
            },
            {
              title: "Asiatische Frisch- & Naturprodukte",
              img: "curma.jpg",
              content:
                "Die Sprossen wie auch andere leckere Produkte von Urai und Martin setzen wir sehr gerne bei Salaten und Garnituren ein. Man merkt, dass sie ihre Sprossen mit viel Liebe, frischem Wasser, Luft, Licht und Bewegung aufziehen. Da die Sprossen immer nur ein paar Stunden vor der Verarbeitung geschnitten werden, sind sie hervorragend in der Qualität.",
              photo: "curma-1-min.jpg",
              address: `<p>Asiatische <br>
Frisch- & Naturprodukte<br>
Urai + Martin Allenspach<br>
Rotzbergstrasse 15<br>
CH - 6362 Stansstad<br>
<a href="http://www.urma.net">www.urma.net</a></p>`,
            },
            {
              title: "Geissäheimet Meierskählen",
              img: "geissaheimet.jpg",
              content:
                "Seit 1990 widmet sich Toni Odermatt und sein Team ausschliesslich der Ziegenhaltung. Dabei sind es die Ziegen sowie die Mutter Natur, die den Arbeitsrhythmus auf dem Land und in der Hofmanufaktur vorgeben. Das steile Land von Odermatt eignet sich hervorragend für die Bewirtschaftung der Ziegen. Auf den Weiden mit Waldrändern und Hecken können sie ihre artspezifische Verhaltensweise ausleben. Dabei stehen den neugierigen Tieren die besten Gräser und Kräuter zur Auswahl.",
              photo: "geiss-1-min.png",
              address: `<p>Geissäheimet Meierskählen<br>
Toni Odermatt<br>
Meierskählen 1<br>
6370 Stans<br>
<a href="http://www.geisseheimet.ch">www.geisseheimet.ch</a></p>`,
            },
            {
              title: "EL IMPOSIBLE ROASTERS GMBH",
              img: "roasters.jpg",
              content:
                "André kennt Mario und sein Team schon seit einigen Jahren. Er hatte die Möglichkeit, die Entwicklung der EL IMPOSIBLE ROASTERS von Anfang an mitzuverfolgen. Und es ist schön zu sehen, wohin es sie geführt hat. Darum liegt es auf der Hand, welchen Kaffee wir im Restaurant servieren.",
              photo: "roasters-1-min.jpg",
              address: `<p>EL IMPOSIBLE ROASTERS GMBH
Kantonstrasse 30<br>
6048 Horw<br>
<a href="http://www.roasters.ch">www.roasters.ch</a></p>`,
            },
            {
              title: "PASTARAZZI",
              img: "pasta.jpg",
              content:
                "Die Pastarazzi-Crew sowie ihre Produkte haben uns seit dem ersten Kontakt begeistert. Es passt einfach von A-Z. Darum arbeiten wir auch so gerne mit den schmackhaften Teigspezialitäten der «Teigaffen».",
              photo: "pasta-min.png",
              address: `<p>PASTARAZZI Sarnen / Stans / Luzern<br>
<a href="http://www.pastarazzi.ch">www.pastarazzi.ch</a></p>`,
            },
            {
              title: "GERONIMO BRÄU",
              img: "geronimo.jpg",
              content:
                "Walti und sein Geronimo-Bier aus dem Hexenkessel über dem Feuer. Klingt nicht nur geheimnisvoll lecker, sondern ist es auch. Er und seine tatkräftigen Helfer brauen verschiedene, erfrischende Biere. Einige davon sind auch in unserer Karte zu finden.",
              photo: "geronimo-1-min.png",
              address: `<p>Obermatt 6<br>
6388 Grafenort</p>`,
            },
            {
              title: "PONÄLY",
              img: "ponaly.jpg",
              content:
                "Ponäly – s‘Bier vo disnä Cheibä. Eine wahre Erfolgsgeschichte, wenn man bedenkt, dass die Freunde von Hobbybrauern zu Kleinunternehmern wurden. Ihre leckeren Biere sind immer wieder ein Genuss.",
              photo: "ponaly-1-min.png",
              address: `PONÄLY
<p>S'Bier vo disnä Cheibä<br>
Brauerei<br>
Bitzighoferstrasse 11<br>
6060 Sarnen<br>
<a href="http://www.ponäly.ch">www.ponäly.ch</a><p/>`,
            },
            {
              title: "Bergkäse",
              img: "bergkase.jpg",
              content:
                "Bruno und Lucia beliefern seit über zehn Jahren zufriedene Gastronomen in der Region Zentralschweiz, Aargau, Solothurn, Zürich und Basel mit ihrem hervorragenden, urchigen Bergkäse. Auch uns hat ihr Käse überzeugt.",
              photo: "bergkase-1-min.jpg",
              address: `
<p>Bergkäse<br>
Bruno und Lucia Wittwer-Ming<br>
Brünigstrasse 3<br>
6020 Emmenbrücke<br>
<a href="http://www.fromsuisse.ch">www.fromsuisse.ch</a><p/>`,
            },
          ],
        },
      ],
      activity: null,
      activityPreview: null,
      videos: [],
      options: {
        type: "slide",
        focus: "center",
        trimSpace: false,
        fixedWidth: "90%",
        arrows: false,
      },
      menuItems: [
        {
          name: "Home",
          url: "/#",
        },
        {
          name: "About Us",
          url: "#location-our-team",
        },
        // {
        //   name: "Products",
        //   url: "#products",
        // },
        {
          name: "Contact Us",
          url: "#location-contact-us",
        },
      ],
      alpHeads: [
        {
          name: this.$t("team[0].name"),
          title: "",
          desc: this.$t("team[0].desc"),
          url: "team/andre.jpeg",
        },
        {
          name: this.$t("team[1].name"),
          title: "",
          desc: this.$t("team[1].desc"),
          url: "team/marie.jpeg",
        },
        {
          name: this.$t("team[2].name"),
          title: "",
          desc: this.$t("team[2].desc"),
          url: "team/carlos.jpeg",
        },
        {
          name: this.$t("team[4].name"),
          title: "",
          desc: this.$t("team[4].desc"),
          url: "team/gian.jpeg",
        },
      ],
    };
  },
};
</script>
