import VueI18n from 'vue-i18n'
import Vue from 'vue'

Vue.use(VueI18n);
const lang = localStorage.getItem('lang');
const locale =  lang == 'EN' ? 'en-US' : 'de-CH'

export default new VueI18n({
    locale,
    messages: {
        'en-US': {
            lang: "EN",
            spannortWelcome: 
            `
            <style>
            table {
                display: table;
            }
            table tr {
                display: table-cell;
                vertical-align: top;
            }
            table tr td {
                display: block;
            }
            </style>
<div style="display: flex; column-gap: 1rem;">
    <div style="width: 100%;">
        <strong>Opening hours</strong>
        <table>
        <tr>
        <td>Monday day off</td>
        <td>Tuesday day off</td>
        <td>Wednesday from 6 p.m.</td>
        <td>Thursday from 6 p.m. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
        <td>Friday from 6 p.m.</td>
        <td>Saturday from 6 p.m.</td>
        <td>Sunday from 3 p.m.</td>
        </tr>
        </table>
        <br/>
        <strong>Vacations</strong>
        <br/>
        From October 28th, 2024, we will be taking a creative break for three weeks. 
        The RESTAURANT SPANNORT will open on Wednesday, November 20st, 2024.<br/><br/>
        We are looking forward to your reservation&nbsp;by phone<br/>
        +41(0)41 500 11 33 or with the online- tool.<br/>
    </div>
</div>
            `,
            restarauntOpening: "Spring menu",
            philosophyText: `Every day we give our very best – when preparing fresh, regional produce in the 
            kitchen and when serving the delicious dishes in the cosy dining area. Our culinary 
            creations, personal charm, and warm hospitality are guaranteed to win over diners’ 
            hearts and stomachs. This truly is service with a smile as we try to ensure our guests 
            feel pampered and cared for. We take pride in creating a relaxing, convivial 
            atmosphere where diners can sit back and enjoy!<br/><br/>
            The seasonal menu is accompanied by a regularly adapted 5-course menu “our story”`,
            philosophy : "Philosophy",
            introTitle: "Autumnal treats",
            introText: `A warm welcome to Restaurant Spannort, where things are getting wild and autumnal. 
            Enchant your senses with our creative culinary compositions conjured from fresh, regional 
            produce. With every bite you can see, taste and feel the loving attention to detail that we 
            invest in all our autumn creations. Enjoy the hospitality of our charming and competent young 
            team of servers as you settle down to a delicious meal in our cosy restaurant.<br/><br/>
            We are looking forward to spoiling you with unforgettable culinary moments from our autumn 
            menu.`,
            openingHours: `
            <p><strong>Opening hours</strong></p>
            <table>
            <tr>
            <td>Monday day off</td>
            <td>Tuesday day off</td>
            <td>Wednesday from 6 p.m.</td>
            <td>Thursday from 6 p.m. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
            <td>Friday from 6 p.m.</td>
            <td>Saturday from 6 p.m.</td>
            <td>Sunday from 3 p.m.</td>
            </tr>
            </table>
            `,
            team: [
                {
                    name: "Andre Keller",
                    title: "",
                    desc: `
                    The restaurant’s owner is a qualified chef, but in the kitchen his talents as a host were hidden behind the scenes. And so he came to the front of the house, where his witticisms and humour keep the service team and guests well entertained. His enthusiasm as the host of Restaurant Spannort carries over into his private life, where he is a dedicated husband and daddy and a keen snowboarder.`,
                    url: "",
                },
                {
                    name: "Maria Franz",
                    title: "",
                    desc: `
                    If you’re not sure what beverage to pair with what meal, our passionate chef de service Maria is always happy to share her extensive expertise in all things wine. She stays consistently informed about the latest developments, and knows everything there is to know about Engelberg’s restaurant scene.`,
                    url: "",
                },
                {
                    name: "Carlos Szentgroti",
                    title: "",
                    desc: `
                    Originally from Hungary, chef Carlos has many years’ experience in Switzerland and abroad. And he loves to share that experience with our guests in the shape of many and varied culinary creations. Carlos isn’t just a dab hand in the kitchen, he is also a family man who enjoys spending time with his loved ones. In his free time, he keeps in shape by pumping iron.`,
                    url: "",
                },
                {
                    name: "Nico Fischer",
                    title: "",
                    desc: `
                    Our sous chef Nico bears a remarkable resemblance to King of Thrones royal rogue Joffrey Baratheon – but he’s much nicer, we promise! Nico is a qualified pâtissier whose desserts are simply to die for! He has set up his own gym at home, and his dream is to buy a swish new car.`,
                    url: "",
                },
                {
                    name: "Gian Lippuner",
                    title: "",
                    desc: `
                    With his Schaffhausen dialect, trainee chef Gian has brought fresh wind into our kitchen. No one here pronounces “lime and ginger” quite as charmingly as he does ! Gian travels regularly across half the country between his workplace, his school, and his family home back in Eastern Switzerland. In his free time he loves to get out his mountain bike and head for the hills.`,
                    url: "",
                },
            ],
            'contactFormResponseError': "An error occured.",
            'contactFormResponseSuccess' : "Thank you for contacting us."

        },
        'de-CH': {
            lang: "DE",
            spannortWelcome: 
            `
            <style>
            table {
                display: table;
            }
            table tr {
                display: table-cell;
                vertical-align: top;
            }
            table tr td {
                display: block;
            }
            </style>
<div style="display: flex; column-gap: 1rem;">
    <div style="width: 100%;">
        <strong>Öffnungszeiten</strong>
        <table>
        <tr>
        <td>Montag Ruhetag</td>
        <td>Dienstag Ruhetag</td>
        <td>Mittwoch ab 18.00 Uhr </td>
        <td>Donnerstag ab 18.00 Uhr </td>
        <td>Freitag ab 18.00 Uhr </td>
        <td>Samstag ab 18.00 Uhr </td>
        <td>Sonntag ab 15.00 Uhr </td>
        </tr>
        </table>
        <br/>
        <strong>Betriebsferien</strong><br/>
        Ab dem 28. Oktober 2024 erlauben wir uns, für drei Wochen eine Kreativpause einzulegen. 
        Am Mittwoch, 20. November 2024, ist das RESTAURANT SPANNORT wieder für euch geöffnet.<br/><br/>
        Wir freuen uns auf Ihre Reservation&nbsp;telefonisch unter&nbsp; 
        +41(0)41 500 11 33 oder mit dem Online-Tool. <br/>
    </div>
</div>
            `,
            introTitle: 'Herbstlicher Genuss',
            introText: `Herzlich willkommen bei uns im RESTAURANT SPANNORT. Es wird wild und herbstlich bei 
            uns! Lassen Sie Ihre Sinne von unseren kreativen Menükompositionen aus frischen und 
            regionalen Produkten begeistern. Mit jedem Bissen fühlen, schmecken und sehen Sie die 
            Liebe zum Detail, die wir in unsere Herbstgerichte legen. Unser junges und aufgewecktes 
            Serviceteam macht mit viel Charme Ihren Besuch in unseren heimeligen Räumlichkeiten zu 
            einem komplett gelungenen Erlebnis.<br/><br/>
            Wir freuen uns darauf, Sie bald bei uns zu begrüssen und Ihnen unvergessliche kulinarische 
            Momente mit unserer Herbstkarte zu bereiten.`,
            restarauntOpening: "Restaurant Eröffnung",
            openingText: `<br>
DANKE…<br>
	…für viele lustige und interessante Zusammentreffen.<br>
	…dass ihr unsere Empfehlungen schätzt und liebt.<br>
	…für eure Treue und euer Vertrauen. <br>

    <br><br>
Wir schauen auf einen geglückten Start zurück und sind sehr dankbar, dass ihr uns an der Dorfstrasse dermassen herzlich empfangen habt. Der DANK geht besonders an unsere Gäste, die uns besucht und somit unterstütz haben, die Geschichte des RESTAURANT SPANNORT weiterzuschreiben. 
<br><br>
Unglaublich, aber wahr, dass wir bereits in kurzer Zeit unzählige Stammgäste kennen dürfen. Ihr macht den SPANNORT zu etwas ganz Besonderem. Wir fühlen uns wertgeschätzt und sehr geehrt, euch bei uns zu empfangen. Auf viele weitere lustige Zusammentreffen mit euch freuen wir uns.
<br><br>
Danke an alle Lieferanten und Produzenten, die mit ihren erstklassigen Produkten die Herzen unserer Gäste miterobert haben und auch weiter erobern werden. 
<br><br>
Ein grosses Dankeschön richten wir an die Familie Kurer für die angenehme Zusammenarbeit und für das Vertrauen, das ihr uns schenkt. 
<br><br>
Wir freuen uns.<br>
Euer SPANNORT Team
            `,
            "About Us" : "ÜBER UNS",
            "Products" : "Produkte",
            "Contact" : "Kontakt",
            "Contact us" : "Kontakt",
            "Book your table here" : "Bitte buchen Sie ihren Tisch hier",
            philosophyText: `Wir geben täglich unser Bestes – beim Zubereiten der regionalen und frischen 
            Produkte in der Küche oder beim Anrichten der kreativen Gerichte. Doch nicht nur 
            das Angebot auf dem Teller soll überzeugen, sondern auch die Gastfreundschaft. Wir 
            begrüssen unsere Gäste mit einem herzlichen Lachen und begleiten sie mit viel 
            Charme durch den Abend. In unserem Restaurant finden Sie eine Atmosphäre, in der 
            Sie sich zurücklehnen und geniessen können.<br/><br/>
            Die saisonale Menükarte wird durch ein regelmässig angepasstes 5- Gänge Menü 
            “unserem G\`schichtli“ begleitet.`,
            philosophy : "Philosophie",
            openingHours: `
            <p><strong>Öffnungszeiten</strong></p>
            <table>
            <tr>
            <td>Montag Ruhetag</td>
            <td>Dienstag Ruhetag</td>
            <td>Mittwoch ab 18.00 Uhr </td>
            <td>Donnerstag ab 18.00 Uhr </td>
            <td>Freitag ab 18.00 Uhr </td>
            <td>Samstag ab 18.00 Uhr </td>
            <td>Sonntag ab 15.00 Uhr </td>
            </tr>
            </table>
            `,
            "WELCOME" : "Willkommen",
            "Meet our Team" : "Unser Team",
            "Read more" : "Weiterlesen",
            team: [
                {
                    name: "Andre Keller",
                    title: "",
                    desc: `
                    Unser Gastgeber wüsste als gelernter Koch auch in der Küche zu überzeugen – aber dort kämen seine Gastgeberqualitäten nicht zum Tragen. Mit seinen Sprüchen und seinem Humor unterhält er Team und Gäste gleichermassen. Denselben Enthusiasmus wie im RESTAURANT SPANNORT legt der Papi auch zu Hause in seinem Frauenhaushalt oder auf dem Snowboard an den Tag.`,
                    url: "",
                },
                {
                    name: "Maria Franz",
                    title: "",
                    desc: `Sie wissen nicht, welches Getränk zum Menü passen könnte? Unsere herzliche Chef de Service hat immer den passenden Tipp auf Lager. Mit viel Leidenschaft und Expertise teilt sie ihr unglaubliches Wissen mit den Gästen. Ausbildungstechnisch bleibt sie stets am Puls der Zeit und auch Engelbergs Gastroszene kennt sie bestens.`,
                    url: "",
                },
                {
                    name: "Carlos Szentgroti",
                    title: "",
                    desc: `Der gebürtige Ungar überzeugt seit mehreren Jahren als Küchenchef im In - und Ausland mit seinen leckeren Menükreationen. Doch Carlos ist nicht nur ein Meister am Herd, sondern auch ein Familienmensch, der gerne Zeit mit seinen Liebsten verbringt. Vollen Krafteinsatz gibt er zudem beim Gewichtestemmen in seiner Freizeit.`,
                    url: "",
                },
                {
                    name: "Nico Fischer",
                    title: "",
                    desc: `
                    Steht da King Joffrey aus King of Thrones in der Spannort - Küche? Nein, nicht ganz. Aber unser Sous Chef sieht ihm zum Verwechseln ähnlich! Als ausgebildeter Pâtissier vermag er unsere Gäste statt mit adligem Blut mit seinen Dessertkreationen um den Finger zu wickeln. Nico hat zu H ause ein eigenes Fitnessstudio eingerichtet und träumt von einem neuen Auto.`,
                    url: "",
                },
                {
                    name: "Gian Lippuner",
                    title: "",
                    desc: `
                    Unser Lernender bringt mit seinem Schaffhauser - Dialekt eine willkommene Abwechslung in unsere Küche: niemand kann so schön «Limetten - Ingwer» sagen wie er . Für seine Kochausbildung in Engelberg pendelt der Ostschweizer für Schule und die freien Tage bei seiner Familie mit dem Zug durch die halbe Schweiz. In seiner Freizeit befindet er sich am liebsten auf dem Mountainbike.`,
                    url: "",
                },
            ],
            'contactFormResponseError': "Es ist ein Fehler aufgetreten.",
            'contactFormResponseSuccess' : "Danke, dass Sie uns kontaktiert haben.",
            "Send us a message": "Uns eine Nachricht senden",
            "Full name": "Name",
            "Company" : "Unternehmen",
            "Enter something..." : "Gib etwas ein ...",
            "Menu" : "Menükarte",
            "Food/Drink": "Essen / Trinken",
            "Reservations": "Reservationen",
            "Wine list": "Weinkarte"
        }
    }
})
